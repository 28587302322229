import Button from '@components/shared/Button'
import { getWrappedToken } from 'config/tokens'
import { approveTokens } from 'domain/tokens'
import { isAddressZero } from 'gmx/lib/legacy'
import { usePendingTxns } from 'gmx/lib/usePendingTxns'
import useWallet from 'gmx/lib/wallets/useWallet'
import { usePaymaster } from 'hooks/usePaymaster'
import { useState } from 'react'
import { ImSpinner2 } from 'react-icons/im'

type Props = {
  spenderAddress: string
  tokenAddress: string
  tokenSymbol: string
  isApproved?: boolean
  isPadding?: boolean
  text?: string
  size?: 'small' | 'medium' | 'large'
}

export function ApproveTokenButton(p: Props) {
  const { size = 'large' } = p
  const { signer, chainId } = useWallet()
  const [pendingTxns, setPendingTxns] = usePendingTxns()
  const [isApproving, setIsApproving] = useState(false)
  const [isApproveSubmitted, setIsApproveSubmitted] = useState(false)
  const { sendPaymasterTransaction } = usePaymaster()
  const { account } = useWallet()
  function onApprove() {
    if (!chainId || isApproveSubmitted || p.isApproved) {
      return
    }

    const wrappedToken = getWrappedToken(chainId)
    const tokenAddress = isAddressZero(p.tokenAddress)
      ? wrappedToken.address
      : p.tokenAddress

    approveTokens({
      setIsApproving,
      signer,
      tokenAddress: tokenAddress,
      spender: p.spenderAddress,
      pendingTxns,
      setPendingTxns,
      infoTokens: {},
      chainId,
      sendPaymasterTransaction,
      account,
      onApproveSubmitted: () => setIsApproveSubmitted(true),
    })
  }

  const isLoading = isApproving || (isApproveSubmitted && !p.isApproved)

  return (
    <Button
      className="flex w-full flex-1 cursor-pointer select-none items-center justify-center text-sm font-bold uppercase"
      onClick={onApprove}
      disabled={isLoading}
      size={size}
    >
      {isLoading ? (
        <ImSpinner2 className="spin" />
      ) : (
        <>{p?.text || `Approve ${p.tokenSymbol}`}</>
      )}
    </Button>
  )
}
