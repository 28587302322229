import useSettingsStore from '@store/settingsStore'
import useBreakpoints from 'hooks/useBreakpoints'
import Image from 'next/image'
import { ChangeEventHandler, KeyboardEvent } from 'react'
import { cn } from 'utils/classnames'

type Props = {
  value: string
  setValue: ChangeEventHandler<HTMLInputElement>
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void
  className?: string
  placeholder?: string
  inputBoxClasses?: string
  as?: React.ElementType
  iconSize?: 'regular' | 'small'
}

export default function SearchInput({
  value,
  setValue,
  onKeyDown,
  className,
  placeholder,
  inputBoxClasses,
  iconSize,
  as: Tag = 'div',
}: Props) {
  const { above } = useBreakpoints()
  const isPro = useSettingsStore((state) => state.isPro())
  const dimension = isPro ? 16 : iconSize === 'small' ? 16 : 24
  const paddingX = above.lg && !isPro ? 'px-6' : 'px-4'

  return (
    <Tag
      className={cn(
        'flex items-center gap-2 rounded-md border border-th-input-border-secondary bg-th-bkg-3 px-4',
        paddingX,
        className,
      )}
    >
      <Image
        alt="search address"
        width={dimension}
        height={dimension}
        src="/icons/search-normal.svg"
      />
      <input
        id="searchInputElement"
        type="text"
        autoComplete="off"
        placeholder={placeholder ?? `Search Token`}
        value={value}
        onChange={setValue}
        onKeyDown={onKeyDown}
        autoFocus={above.lg}
        className={cn(
          'w-full select-none bg-transparent text-sm font-normal text-th-fgd-2 outline-none sm:text-base',
          inputBoxClasses,
        )}
      />
    </Tag>
  )
}
