import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react'
import { CLOUD_FRONT_URL } from 'config/constants'
import Image from 'next/image'
import ChevronDownIcon from 'public/icons/chevron.svg'
import { Fragment } from 'react'
import { cn } from 'utils/classnames'
import CountContainer from './CountContainer'

interface Props {
  appliedFilter: string
  changeFilter: (val: string) => void
  options: { label: string; count: number }[]
}

const TokenFilterDropdown = ({
  appliedFilter,
  changeFilter,
  options,
}: Props) => {
  const activeFilterCount = options.find(
    (opt) => opt.label === appliedFilter,
  )?.count

  return (
    <Menu as="div">
      <MenuButton as={Fragment}>
        {({ active }) => (
          <div className="relative flex items-center gap-2 rounded border border-th-input-border-secondary bg-th-bkg-3 p-2">
            <div className="flex items-center gap-2">
              <p className="text-xs font-medium text-th-fgd-1">
                {appliedFilter}
              </p>
              {activeFilterCount ? (
                <CountContainer value={activeFilterCount} color="#fff" />
              ) : null}
            </div>

            <ChevronDownIcon
              className={cn(active && 'rotate-180 transition-all duration-75')}
            />
          </div>
        )}
      </MenuButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems
          anchor="bottom start"
          className="z-40 mt-2 rounded border border-th-input-border-secondary outline-none"
        >
          <div className="absolute h-full w-full rounded bg-th-bkg-5 opacity-80" />
          <div className="glass-bg-light absolute inset-0 left-0" />
          <Image
            alt=""
            className="pointer-events-none select-none"
            src={CLOUD_FRONT_URL + '/images/bg-noise.png'}
            layout="fill"
            objectFit="cover"
          />
          <div className="relative z-20 flex flex-col gap-[8px] p-[8px]">
            {options.map((opt) => (
              <MenuItem key={opt.label} as={Fragment}>
                <div
                  onClick={() => changeFilter(opt.label)}
                  className={cn(
                    'flex h-8 max-w-fit items-center gap-2 rounded border border-th-input-border px-2',
                    appliedFilter === opt.label ? 'bg-th-fgd-1' : 'bg-th-bkg-2',
                  )}
                >
                  <p
                    className={cn(
                      'text-sm',
                      appliedFilter === opt.label
                        ? 'font-semibold text-th-bkg-1'
                        : 'font-medium text-th-fgd-2',
                    )}
                  >
                    {opt.label}
                  </p>
                  {opt.count ? (
                    <CountContainer
                      value={opt.count}
                      color={
                        appliedFilter === opt.label ? '#111113' : '#909096'
                      }
                      textClasses={
                        appliedFilter === opt.label
                          ? 'text-th-bkg-1'
                          : 'text-th-fgd-3'
                      }
                    />
                  ) : null}
                </div>
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  )
}

export default TokenFilterDropdown
