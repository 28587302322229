import { EvmPriceServiceConnection } from '@pythnetwork/pyth-evm-js'
import { BigNumber } from 'ethers'
import { ENABLE_LOGGING } from 'utils/constants'
import { create } from 'zustand'
import { devtools, subscribeWithSelector } from 'zustand/middleware'
import { getActionName } from './utils'

export type TokenPriceInfo = {
  minPrice: BigNumber
  maxPrice: BigNumber
  ts: number
}

const STORE_NAME = 'prices'

export type PriceStoreType = {
  prices: Record<string, TokenPriceInfo>
  setTokenPrice: (tokenAddress: string, price: TokenPriceInfo) => void
  setPricesUsingApi: (prices: PriceStoreType['prices']) => void
  isWSBlocked: boolean
  setIsWSBlocked: (isWSBlocked: boolean) => void
  pythIdsForWS: string[]
  setPythIdForWS: (pythIdForWS: string | string[]) => void
  removePythIdForWS: (pythIdForWS: string | string[]) => void
  wsConnectionInfo: {
    ref: EvmPriceServiceConnection | null
    updatedAt: Date
  }
  setWsConnectionInfo: (
    wsConnectionRef: PriceStoreType['wsConnectionInfo'],
  ) => void
}

const priceStore = create<PriceStoreType>()(
  devtools(
    subscribeWithSelector((set) => ({
      prices: {},
      setTokenPrice(tokenAddress, priceInfo) {
        set(
          ({ prices }) => {
            const currentPriceInfo = prices[tokenAddress] as
              | TokenPriceInfo
              | undefined

            const shouldUpdate =
              priceInfo.ts - (currentPriceInfo?.ts || 0) > 500

            if (shouldUpdate) {
              return {
                prices: {
                  ...prices,
                  [tokenAddress]: priceInfo,
                },
              }
            }
            return {}
          },
          false,
          getActionName(STORE_NAME, 'setTokenPrice'),
        )
      },
      isWSBlocked: true,
      setIsWSBlocked(isWSBlocked) {
        set({ isWSBlocked }, false, getActionName(STORE_NAME, 'setIsWSBlocked'))
      },
      setPricesUsingApi(pricesFromApi) {
        set(
          ({ prices }) => ({
            prices: {
              ...prices,
              ...pricesFromApi,
            },
          }),
          false,
          getActionName(STORE_NAME, 'setPricesUsingApi'),
        )
      },
      wsConnectionInfo: {
        ref: null,
        updatedAt: new Date(),
      },
      setWsConnectionInfo(wsConnectionInfo) {
        set(
          { wsConnectionInfo },
          false,
          getActionName(STORE_NAME, 'setWsConnectionInfo'),
        )
      },
      pythIdsForWS: [],
      setPythIdForWS(pythId) {
        set(({ pythIdsForWS }) => {
          if (pythId && !pythIdsForWS.some((id) => id === pythId)) {
            return {
              pythIdsForWS:
                typeof pythId === 'object'
                  ? [...pythIdsForWS, ...pythId]
                  : [...pythIdsForWS, pythId],
            }
          }
          return {}
        })
      },
      removePythIdForWS(pythId) {
        set(
          ({ pythIdsForWS }) => ({
            pythIdsForWS: pythIdsForWS.filter((id) => id !== pythId),
          }),
          false,
          getActionName(STORE_NAME, 'removePythIdForWS'),
        )
      },
    })),
    { enabled: ENABLE_LOGGING, name: 'prices' },
  ),
)

export default priceStore
