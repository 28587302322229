import { BigNumber } from 'ethers'

// @see https://github.com/gmx-io/gmx-synthetics/blob/6ed9be061d8fcc0dc7bc5d34dee3bf091408a1bf/contracts/gas/GasUtils.sol#L218-L234
export function estimateDepositOraclePriceCount(swapsCount: number): BigNumber {
  return BigNumber.from(3).add(BigNumber.from(swapsCount))
}

export function estimateWithdrawalOraclePriceCount(
  swapsCount: number,
): BigNumber {
  return BigNumber.from(3).add(BigNumber.from(swapsCount))
}

export function estimateOrderOraclePriceCount(swapsCount: number): BigNumber {
  return BigNumber.from(3).add(BigNumber.from(swapsCount))
}
