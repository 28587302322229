import { MarketCardInfo } from 'components/Synthetics/MarketCard/MarketCardPricesUpdater'
import { ExecutionFee } from 'domain/synthetics/fees/types'
import { MarketInfo } from 'domain/synthetics/markets'
import { PositionInfo } from 'domain/synthetics/positions'
import { TokenData, TokensRatio } from 'domain/synthetics/tokens/types'
import {
  DecreasePositionAmounts,
  FindSwapPath,
  IncreasePositionAmounts,
  NextPositionValues,
  SwapAmounts,
  TradeFees,
  TradeFeesType,
} from 'domain/synthetics/trade/types'
import { AvailableMarketsOptions } from 'domain/synthetics/trade/useAvailableMarketsOptions'
import { TradeFlags } from 'domain/synthetics/trade/useTradeFlags'
import { BigNumber } from 'ethers'
import { InitialEntry } from 'hooks/tradebox/sidecarOrders/types'
import is from 'utils/common/objectIs'
import { create } from 'zustand'
import { useStoreWithEqualityFn } from 'zustand/traditional'

export type TradeboxStoreType = {
  increaseAmounts: IncreasePositionAmounts | undefined
  decreaseAmounts: DecreasePositionAmounts | undefined
  nextPositionValues: NextPositionValues | undefined
  markPrice: BigNumber | undefined
  markRatio: TokensRatio | undefined
  triggerRatio: TokensRatio | undefined
  swapAmounts: SwapAmounts | undefined
  fromTokenAmount: BigNumber
  fromUsd: BigNumber | undefined
  toTokenAmount: BigNumber
  fees: TradeFees | undefined
  feesType: TradeFeesType | undefined
  executionFee: ExecutionFee | undefined
  marketsOptions: AvailableMarketsOptions
  longLiquidity: BigNumber
  shortLiquidity: BigNumber
  isOutPositionLiquidity: boolean
  submitButtonText: string
  formError: string | undefined
  marketCardInfo: MarketCardInfo
  slippage: number
  useOneClickTrading: boolean
  existingLimitOrderEntries: InitialEntry[] | undefined
  existingTpOrderEntries: InitialEntry[] | undefined
  existingSlOrderEntries: InitialEntry[] | undefined
  marketInfo: MarketInfo | undefined
  orderInfo: {
    tradeFlags: TradeFlags | undefined
    findSwapPath: FindSwapPath | undefined
    marketInfo: MarketInfo | undefined
    collateralToken: TokenData | undefined
    triggerPrice: BigNumber | undefined
    existingPosition: PositionInfo | undefined
    positionKey: string
    nextPositionValues: NextPositionValues | undefined
    markPrice: BigNumber | undefined
    increaseAmounts: IncreasePositionAmounts | undefined
  }
  updateProp: <TKey extends keyof TradeboxStoreType>(
    key: TKey,
    value: TradeboxStoreType[TKey],
  ) => void
}
const tradeboxStore = create<TradeboxStoreType>((set) => ({
  increaseAmounts: undefined,
  decreaseAmounts: undefined,
  nextPositionValues: undefined,
  markPrice: undefined,
  markRatio: undefined,
  triggerRatio: undefined,
  marketInfo: undefined,
  swapAmounts: undefined,
  fromTokenAmount: BigNumber.from(0),
  fromUsd: undefined,
  toTokenAmount: BigNumber.from(0),
  fees: undefined,
  feesType: undefined,
  executionFee: undefined,
  marketsOptions: {},
  shortLiquidity: BigNumber.from(0),
  longLiquidity: BigNumber.from(0),
  isOutPositionLiquidity: false,
  submitButtonText: '',
  formError: undefined,
  marketCardInfo: {
    longLiquidity: BigNumber.from(0),
    shortLiquidity: BigNumber.from(0),
    maxReservedUsd: BigNumber.from(0),
    reservedUsd: BigNumber.from(0),
    borrowingRate: BigNumber.from(0),
    fundingRateLong: BigNumber.from(0),
    fundingRateShort: BigNumber.from(0),
    totalInterestUsd: BigNumber.from(0),
    priceDecimals: undefined,
    currentOpenInterest: BigNumber.from(0),
    maxOpenInterest: BigNumber.from(0),
  },
  slippage: 50,
  useOneClickTrading: true,
  existingLimitOrderEntries: undefined,
  existingTpOrderEntries: undefined,
  existingSlOrderEntries: undefined,
  orderInfo: {
    tradeFlags: undefined,
    findSwapPath: undefined,
    marketInfo: undefined,
    collateralToken: undefined,
    triggerPrice: undefined,
    existingPosition: undefined,
    positionKey: '',
    nextPositionValues: undefined,
    markPrice: undefined,
    increaseAmounts: undefined,
  },
  updateProp: (key, value) => {
    set({ [key]: value })
  },
}))

export const areTwoBigNumbersEqual = (
  a: BigNumber | undefined,
  b: BigNumber | undefined,
) => {
  if (a !== undefined && b !== undefined) {
    return a.eq(b)
  } else if (a === undefined || b !== undefined) {
    return false
  } else if (a !== undefined || b === undefined) {
    return false
  }
  return true
}

export function useTradeboxStoreBigNumberSelector(
  selector: (state: TradeboxStoreType) => BigNumber | undefined,
) {
  return useStoreWithEqualityFn(tradeboxStore, selector, areTwoBigNumbersEqual)
}

const areTwoBigNumbersEqualArrSelector = (
  oldArr: any,
  newArr: any,
): boolean => {
  if (oldArr.length !== newArr.length) {
    return false
  }
  const isEqual = (oldArr as any[]).some((oldElem, idx) => {
    const newElem = newArr[idx]
    if (is(oldElem, newElem)) {
      return true
    } else if (
      BigNumber.isBigNumber(oldElem) &&
      BigNumber.isBigNumber(newElem)
    ) {
      return oldElem.eq(newElem)
    }
  })
  return isEqual
}

export function useTradeboxStoreBigNumberSelectorArr<U>(
  selector: (state: TradeboxStoreType) => U,
): U {
  return useStoreWithEqualityFn(
    tradeboxStore,
    selector,
    areTwoBigNumbersEqualArrSelector,
  )
}

export default tradeboxStore
