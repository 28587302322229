import { NoSymbolIcon } from '@heroicons/react/20/solid'
import Image from 'next/image'
import { useAccount } from 'wagmi'

const EmptyState = ({
  text,
  textClasses,
  className,
}: {
  text: string
  textClasses?: string
  className?: string
}) => {
  const { isConnected } = useAccount()
  return (
    <div className={`flex flex-1 items-center justify-center ${className}`}>
      <div className="flex flex-col items-center justify-center gap-2">
        {!isConnected ? (
          <Image
            alt=""
            src="/images/login-connect-wallet.svg"
            height={28}
            width={28}
          />
        ) : (
          <NoSymbolIcon className="h-7 w-7 text-th-fgd-3" />
        )}

        <p className={textClasses}>{!isConnected ? 'Connect wallet' : text}</p>
      </div>
    </div>
  )
}

export default EmptyState
