import { DEFAULT_CHAIN_ID } from 'config/chains'
import { NATIVE_TOKEN_ADDRESS } from 'config/tokens'
import { useSubaccount } from 'context/SubaccountContext/SubaccountContext'
import { BigNumber, Contract } from 'ethers'
import { callContract, showCallContractToast } from 'gmx/lib/contracts'
import { TxError, getErrorMessage } from 'gmx/lib/contracts/transactionErrors'
import { helperToast } from 'gmx/lib/helperToast'
import { usePendingTxns } from 'gmx/lib/usePendingTxns'
import React, { useMemo } from 'react'
import { publicClient } from 'utils/rainbow-config'
import { Account, Address, Hex, stringify } from 'viem'
import { eip712WalletActions } from 'viem/zksync'
import { useAccount, useWalletClient } from 'wagmi'

interface SwapCall {
  address: string
  calldata: Hex
  value?: bigint
}

export interface PendingTransaction {
  hash: string
  message: string | undefined
  messageDetails?: React.ReactNode
}

export interface ZyfiResponse {
  txData: TxData
  gasLimit: string
  gasPrice: string
  tokenAddress: string
  tokenPrice: string
  feeTokenAmount: string
  feeTokendecimals: string
  feeUSD: string
  estimatedFinalFeeUSD: string
  estimatedFinalFeeTokenAmount: string
  markup: string
  expirationTime: string
  expiresIn: string
  sponsorshipRatio: number
  warnings: string[]
}

export interface TxData {
  chainId: number
  from: Address
  to: Address
  data: Hex
  value: Hex
  customData: CustomData
  maxFeePerGas: string
  gasLimit: number
}

export interface CustomData {
  paymasterParams: PaymasterParams
  gasPerPubdata: number
}

export interface PaymasterParams {
  paymaster: string
  paymasterInput: string
}

export interface MessageOptions {
  detailsMsg?: React.ReactNode
  sentMsg?: string
  successMsg?: string
  hideSentMsg?: boolean
  hideSuccessMsg?: boolean
  showPreliminaryMsg?: boolean
  failMsg?: string
}

export interface SendPaymasterTransactionArgs {
  call: SwapCall & {
    gas?: string | bigint | undefined
  }
  account: string
  subaccount?: ReturnType<typeof useSubaccount>
  messageOpts?: MessageOptions
  setPendingTxns?: (txns: PendingTransaction[]) => void
  router: Contract
  payload: Array<string | BigNumber | string[]>
  method: string
}
export type SendPaymasterTransactionFn = (
  args: SendPaymasterTransactionArgs,
) => Promise<string>

/**
 * Zyfi Paymaster for the zkSync chain
 */

export const usePaymaster = () => {
  const [pendingTxns] = usePendingTxns()
  const { chain } = useAccount()
  const { data: walletClient } = useWalletClient()

  /**
   * Check if the Paymaster for zkSync is available
   */
  const isPaymasterAvailable = useMemo(() => {
    return chain?.id === DEFAULT_CHAIN_ID
  }, [chain])

  /**
   * Check if a paymaster token is selected.
   * Default is the native token to pay gas
   */
  const isPaymasterTokenActive = NATIVE_TOKEN_ADDRESS

  const sendPaymasterTransaction: SendPaymasterTransactionFn =
    React.useCallback(
      async ({
        call,
        account,
        messageOpts: opts = {},
        setPendingTxns,
        subaccount,
        router,
        payload,
        method,
      }) => {
        if (!account) {
          throw new Error(
            'An active wallet connection is required to send paymaster transaction',
          )
        }
        if (!isPaymasterAvailable || !isPaymasterTokenActive) {
          throw new Error('Paymaster is not available or active.')
        }

        const response = await fetch('/api/paymaster', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },

          body: stringify({
            call,
            account: subaccount?.address || account,
            gasTokenAddress: '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
          }),
        })

        if (!response.ok) {
          throw new Error('Failed to send paymaster transaction')
        }

        const txResponse: ZyfiResponse = await response.json()
        const rawTx = txResponse.txData
        const nonce = await publicClient.getTransactionCount({
          address: (subaccount?.address || account) as `0x${string}`,
        })

        if (
          txResponse.sponsorshipRatio < 100 ||
          txResponse?.warnings?.length > 0
        ) {
          return await callContract(
            chain?.id || DEFAULT_CHAIN_ID,
            router,
            method,
            payload,
            {
              value: BigNumber.from(rawTx.value),
              hideSentMsg: true,
              hideSuccessMsg: true,

              setPendingTxns: setPendingTxns,
              nonce,
            },
          )
        } else {
          const txPayload = {
            account: (subaccount?.account || account) as
              | `0x${string}`
              | Account,
            to: rawTx.to,
            value: BigInt(rawTx.value),
            chain: chain,
            gas: BigInt(rawTx.gasLimit),
            gasPerPubdata: BigInt(rawTx.customData.gasPerPubdata),
            maxFeePerGas: BigInt(rawTx.maxFeePerGas),
            maxPriorityFeePerGas: BigInt(0),
            data: rawTx.data,
            paymaster: rawTx.customData.paymasterParams.paymaster,
            paymasterInput: rawTx.customData.paymasterParams.paymasterInput,
            nonce,
          }

          if (!walletClient) {
            throw new Error('Failed to execute paymaster transaction')
          }

          const client = walletClient.extend(eip712WalletActions())

          try {
            const hash = await client.sendTransaction(txPayload)

            if (!opts.hideSentMsg) {
              showCallContractToast({
                chainId: chain?.id || DEFAULT_CHAIN_ID,
                sentMsg: opts.sentMsg || `Transaction sent.`,
                detailsMsg: opts.detailsMsg || '',
                hash,
              })
            }

            if (setPendingTxns) {
              const message = opts.hideSuccessMsg
                ? undefined
                : opts.successMsg || `Transaction completed!`
              const pendingTxn = {
                hash: hash,
                message,
                messageDetails: opts.detailsMsg,
              }
              setPendingTxns([...pendingTxns, pendingTxn])
            }
            return hash
          } catch (e) {
            const { failMsg, autoCloseToast } = getErrorMessage(
              chain?.id || 0,
              e as TxError,
              opts?.failMsg,
            )

            helperToast.error(failMsg, { autoClose: autoCloseToast })
            throw e
          }
        }
      },
      [
        chain,
        isPaymasterAvailable,
        isPaymasterTokenActive,
        pendingTxns,
        walletClient,
      ],
    )

  return {
    isPaymasterAvailable,
    isPaymasterTokenActive,
    sendPaymasterTransaction,
  }
}
