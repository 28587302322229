import clsx, { ClassValue } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'

const customTwMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      size: [
        'text-xss',
        'text-xs',
        'text-md',
        'text-lg',
        'text-base',
        'text-xl',
        'text-2xl',
        'text-3xl',
        'text-4xl',
        'text-5xl',
        'text-6xl',
        'text-7xl',
        'text-8xl',
        'text-9xl',
        'text-10xl',
      ],
    },
  },
})

export const cn = (...classes: ClassValue[]) => customTwMerge(clsx(...classes))
